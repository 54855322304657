App.handleProject = function() {
	reorderGrid();

	$(window).resize(function(){
		reorderGrid();
	});

	function reorderGrid(){
		if ($(window).width() >= 961 && $(window).width() <= 1080){	
			$('#project8, #project9').appendTo('.js--mosaic__first');
		} else {
			$('#project8, #project9').insertBefore('#project10');
		}
	}
};